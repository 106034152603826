import {
	ContactShadows,
	Environment,
	OrbitControls,
	useGLTF,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { proxy, useSnapshot } from "valtio";

// Add at the top of the file, after imports
const MODEL_Y_POSITION = -0.3; // Adjust this value to move the model up/down
const SHADOW_Y_OFFSET = -0.3; // Shadow offset relative to model position
const ANIMATION_DURATION = 0.5; // Duration in seconds

const MOUNTER_OFFSETS: Record<string, number> = {
	profilo_1_1: -0.03,
	profilo_1_2: -0.015,
	profilo_1_3: -0.001,
	profilo_2_1: -0.005,
	profilo_2_2: 0.01,
	profilo_3_1: -0.02,
	profilo_3_2: -0.005,
};

// Define types for our state
interface State {
	current: string | null;
	isOptionsVisible: boolean;
	isExpanded: boolean;
	activeSet: {
		mounter: number;
		profile: string;
		implant: number;
	};
	items: {
		material: string;
	};
}

// Define types for our sections
interface SectionBase {
	name: string;
	stateKey: keyof State["activeSet"];
}

interface ToggleSection extends SectionBase {
	isToggle: true;
}

interface OptionsSection extends SectionBase {
	options: string[];
	getSize: (option: string) => string;
}

interface ProfileSection extends OptionsSection {
	getAngle: (option: string) => string;
}

type Section = ToggleSection | OptionsSection | ProfileSection;

// Type our mappings
const implantSizes: Record<string, string> = {
	implant_1: "3.5 mm",
	implant_2: "4.0 mm",
	implant_3: "4.5 mm",
	implant_4: "5.0 mm",
};

const profileAngles: Record<string, string> = {
	"1": "0°",
	"2": "17°",
	"3": "30°",
};

const profileSizes: Record<string, string> = {
	profilo_1_1: "1.5 mm",
	profilo_1_2: "2.5 mm",
	profilo_1_3: "3.5 mm",
	profilo_2_1: "2.5 mm",
	profilo_2_2: "3.5 mm",
	profilo_3_1: "3.5 mm",
	profilo_3_2: "4.5 mm",
};

const state = proxy<State>({
	current: null,
	isOptionsVisible: false,
	isExpanded: false,
	activeSet: {
		mounter: 1,
		profile: "profilo_1_1",
		implant: 1,
	},
	items: {
		material: "#ffffff",
	},
});

const sections: Record<string, Section> = {
	profile: {
		name: "Profilo",
		options: [
			"profilo_1_1",
			"profilo_1_2",
			"profilo_1_3",
			"profilo_2_1",
			"profilo_2_2",
			"profilo_3_1",
			"profilo_3_2",
		],
		stateKey: "profile",
		getAngle: (option: string) => profileAngles[option.split("_")[1]],
		getSize: (option: string) => profileSizes[option],
	} as ProfileSection,
	mounter: {
		name: "Mounter",
		stateKey: "mounter",
		isToggle: true,
	} as ToggleSection,
	implant: {
		name: "Implant",
		options: [1, 2, 3, 4].map((num) => `implant_${num}`),
		stateKey: "implant",
		getSize: (option: string) => implantSizes[option],
	} as OptionsSection,
};

// Type for button styles
interface ButtonStyle extends React.CSSProperties {
	":hover"?: React.CSSProperties;
}

// Update the Profile component ref type
function Profile() {
	const ref = useRef<THREE.Group>(null);
	const snap = useSnapshot(state);
	const { nodes, materials } = useGLTF("/implant.glb");
	const [hovered, set] = useState(null);
	const [isIntroAnimating, setIsIntroAnimating] = useState(true);
	const introProgress = useRef(0);
	const mounterPositionRef = useRef(0);
	const implantPositionRef = useRef(0);
	const originalPositions = useRef<Map<string, number>>(new Map());

	console.log(`
=== MODEL STRUCTURE ===
NODES:
${Object.keys(nodes).join("\n")}

MATERIALS:
${Object.keys(materials).join("\n")}
===================
`);

	useEffect(() => {
		if (ref.current) {
			ref.current.rotation.x = 0;
			ref.current.rotation.y = 0;
			ref.current.rotation.z = 0;
			ref.current.scale.set(0.25, 0.25, 0.25);
			ref.current.position.set(0, MODEL_Y_POSITION, 0);

			// Store original positions of all children
			for (const child of ref.current.children) {
				originalPositions.current.set(child.name || "", child.position.y);
			}
		}
		const timer = setTimeout(() => {
			setIsIntroAnimating(false);
		}, 2500);

		return () => clearTimeout(timer);
	}, []);

	useFrame((state, delta) => {
		if (!ref.current) return;

		const t = state.clock.getElapsedTime();
		const hoverY = (1 + Math.sin(t / 1.5)) / 30;

		if (isIntroAnimating) {
			const eased = 1 - (1 - introProgress.current) ** 3;
			introProgress.current = Math.min(introProgress.current + delta * 0.4, 1);

			// Apply base transformations
			ref.current.rotation.x = THREE.MathUtils.lerp(
				0,
				0 + Math.cos(t / 4) / 8,
				eased,
			);
			ref.current.rotation.y = THREE.MathUtils.lerp(
				0,
				Math.sin(t / 4) / 8,
				eased,
			);
			const scale = THREE.MathUtils.lerp(0.25, 2.5, eased);
			ref.current.scale.set(scale, scale, scale);
			ref.current.rotation.z = THREE.MathUtils.lerp(
				0,
				-0.2 - (1 + Math.sin(t / 1.5)) / 20,
				eased,
			);
			ref.current.position.y = THREE.MathUtils.lerp(
				MODEL_Y_POSITION,
				MODEL_Y_POSITION + hoverY,
				eased,
			);

			// Apply positions to objects during intro, preserving original positions
			for (const child of ref.current.children) {
				const originalY = originalPositions.current.get(child.name || "") || 0;
				if (child.name?.includes("mounter")) {
					const mounterOffset = MOUNTER_OFFSETS[snap.activeSet.profile] || 0;
					child.position.y = originalY + mounterOffset;
				} else {
					child.position.y = originalY;
				}
			}
		} else {
			const baseY = MODEL_Y_POSITION + hoverY;

			// Animate positions with reduced distance
			const targetMounterY = snap.isExpanded ? 0.05 : 0;
			const targetImplantY = snap.isExpanded ? -0.1 : 0;

			// Smooth transition using lerp
			mounterPositionRef.current = THREE.MathUtils.lerp(
				mounterPositionRef.current,
				targetMounterY,
				delta / ANIMATION_DURATION,
			);

			implantPositionRef.current = THREE.MathUtils.lerp(
				implantPositionRef.current,
				targetImplantY,
				delta / ANIMATION_DURATION,
			);

			// Apply positions to objects, preserving original positions and adding mounter offsets
			for (const child of ref.current.children) {
				const originalY = originalPositions.current.get(child.name || "") || 0;

				if (child.name?.includes("mounter")) {
					// Get offset based on current active profile instead of mounter type
					const mounterOffset = MOUNTER_OFFSETS[snap.activeSet.profile] || 0;
					child.position.y =
						originalY + mounterPositionRef.current + mounterOffset;
				} else if (child.name?.includes("impianto")) {
					child.position.y = originalY + implantPositionRef.current;
				}
			}

			ref.current.rotation.set(
				0 + Math.cos(t / 4) / 8,
				Math.sin(t / 4) / 8,
				-0.2 - (1 + Math.sin(t / 1.5)) / 20,
			);
			ref.current.position.y = baseY;
		}
	});

	return (
		<group
			ref={ref}
			onPointerOver={(e) => (e.stopPropagation(), set(e.object.material.name))}
			onPointerOut={(e) => e.intersections.length === 0 && set(null)}
			onPointerMissed={() => {
				state.current = null;
				state.isOptionsVisible = false;
			}}
			onClick={(e) => {
				e.stopPropagation();
				const materialName = e.object.material.name;
				if (
					Object.values(sections)
						.flatMap((section) => section.options)
						.includes(materialName)
				) {
					state.current = materialName;
					state.isOptionsVisible = true;
				}
			}}
		>
			<primitive
				object={nodes.mounter_1}
				visible={snap.activeSet.mounter === 1}
				material={materials.pink}
			/>
			<primitive
				object={nodes.mounter_2}
				visible={snap.activeSet.mounter === 2}
				material={materials.blue}
			/>
			<primitive
				object={nodes.mounter_3}
				visible={snap.activeSet.mounter === 3}
				material={materials.yellow}
			/>

			{sections.profile.options.map((meshName) => (
				<primitive
					key={meshName}
					object={nodes[meshName]}
					visible={snap.activeSet.profile === meshName}
					material={materials.pink}
				/>
			))}

			{[1, 2, 3, 4].map((num) => (
				<primitive
					key={`implant_${num}`}
					object={nodes[`impianto_${num}`]}
					visible={snap.activeSet.implant === num}
					material={materials.impianto}
				/>
			))}
		</group>
	);
}

export default function App() {
	const containerRef = useRef<HTMLDivElement>(null);
	const [showQRPopup, setShowQRPopup] = useState(false);

	function QRPopup({ onClose }: { onClose: () => void }) {
		return (
			<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
				<div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full m-4">
					<div className="flex justify-between items-center mb-4">
						<h3 className="text-lg font-semibold">Scan to view in AR</h3>
						<button
							onClick={onClose}
							className="text-gray-500 hover:text-gray-700"
							type="button"
						>
							✕
						</button>
					</div>
					<div className="flex justify-center mb-4">
						<img
							src="/images/config_1.png"
							alt="QR Code"
							className="w-64 h-64 object-contain"
						/>
					</div>
					<p className="text-center text-sm text-gray-600">
						Scan this QR code with your iphone or ipad to view in AR
					</p>
				</div>
			</div>
		);
	}

	return (
		<div
			ref={containerRef}
			style={{ position: "relative", width: "100%", height: "100%" }}
		>
			<div className="absolute top-5 right-5 flex gap-3 z-10">
				<button
					onClick={() => {
						const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
						if (isIOS) {
							window.location.href = "/configs/config_1.usdz";
						} else {
							setShowQRPopup(true);
						}
					}}
					className="item-button"
					type="button"
				>
					<svg viewBox="0 0 32 32" width={20} height={20}>
						<title>AR</title>
						<path d="M16.48,23.54l6-3.27a3.86,3.86,0,0,0,.26-.22.78.78,0,0,0,.1-.15.7.7,0,0,0,.09-.17,1.42,1.42,0,0,0,0-.2.79.79,0,0,0,0-.13V12.6a1,1,0,0,0-.07-.34.36.36,0,0,0,0-.09.85.85,0,0,0-.17-.25l0,0a1.12,1.12,0,0,0-.22-.16l-6-3.27a1,1,0,0,0-1,0l-6,3.27a1.12,1.12,0,0,0-.22.16l0,0a.85.85,0,0,0-.17.25.36.36,0,0,0,0,.09A1,1,0,0,0,9,12.6v6.8a.79.79,0,0,0,0,.13,1.42,1.42,0,0,0,0,.2.7.7,0,0,0,.09.17.78.78,0,0,0,.1.15.7.7,0,0,0,.16.14.7.7,0,0,0,.1.08l6,3.27.06,0s.05,0,.08,0a.86.86,0,0,0,.68,0s.06,0,.08,0ZM11,14.29l4,2.17V21l-4-2.17Zm6,2.17,4-2.17v4.52L17,21Zm-1-6,3.88,2.12L16,14.73,12.12,12.6Zm-12-.1V7A3,3,0,0,1,7,4h3.38a1,1,0,0,1,0,2H7A1,1,0,0,0,6,7v3.38a1,1,0,0,1-2,0ZM28,7v3.38a1,1,0,1,1-2,0V7a1,1,0,0,0-1-1H21.62a1,1,0,0,1,0-2H25A3,3,0,0,1,28,7Zm0,14.61V25a3,3,0,0,1-3,3H21.62a1,1,0,0,1,0-2H25a1,1,0,0,0,1-1V21.61a1,1,0,0,1,2,0ZM11.38,27a1,1,0,0,1-1,1H7a3,3,0,0,1-3-3V21.62a1,1,0,0,1,2,0V25a1,1,0,0,0,1,1h3.38A1,1,0,0,1,11.38,27Z" />
					</svg>
				</button>
			</div>

			<Canvas shadows camera={{ position: [0, 0, 1.8], fov: 45 }}>
				<color attach="background" args={["#f0f0f0"]} />
				<ambientLight intensity={0.5} />
				<hemisphereLight
					intensity={0.7}
					color="#ffffff"
					groundColor="#666666"
				/>
				<directionalLight intensity={0.8} position={[2, 2, 2]} castShadow />
				<directionalLight intensity={0.5} position={[-2, -2, -2]} />
				<Profile />
				<Environment preset="city" />
				<ContactShadows
					position={[0, MODEL_Y_POSITION + SHADOW_Y_OFFSET, 0]}
					opacity={0.25}
					scale={10}
					blur={1.5}
					far={0.8}
				/>
				<OrbitControls
					minPolarAngle={Math.PI / 6}
					maxPolarAngle={Math.PI / 1.5}
					enableZoom={true}
					minDistance={0.5}
					maxDistance={3}
					enablePan={true}
					enableDamping={true}
					dampingFactor={0.05}
					enableRotate={true}
					rotateSpeed={0.5}
					panSpeed={0.5}
					screenSpacePanning={true}
					target={[0, 0, 0]}
					touches={{
						ONE: THREE.TOUCH.ROTATE,
						TWO: THREE.TOUCH.DOLLY_PAN,
					}}
					mouseButtons={{
						LEFT: THREE.MOUSE.ROTATE,
						MIDDLE: THREE.MOUSE.DOLLY,
						RIGHT: THREE.MOUSE.PAN,
					}}
					makeDefault
				/>
			</Canvas>
			<Picker />

			{/* Add QR Popup */}
			{showQRPopup && <QRPopup onClose={() => setShowQRPopup(false)} />}
		</div>
	);
}

function Picker() {
	const snap = useSnapshot(state);
	const [isIntroAnimating, setIsIntroAnimating] = useState(true);

	// Add useEffect to sync with Profile's animation
	useEffect(() => {
		const timer = setTimeout(() => {
			setIsIntroAnimating(false);
		}, 2500); // Same duration as in Profile

		return () => clearTimeout(timer);
	}, []);

	// Update the ordered sections
	const orderedSections = {
		implant: sections.implant,
		profile: sections.profile,
		mounter: sections.mounter,
	};

	const handleMounterToggle = () => {
		if (!snap.activeSet.profile) return;

		// Get the series number (1, 2, or 3) from the current profile option
		const seriesNum = Number.parseInt(snap.activeSet.profile.split("_")[1]);

		if (snap.activeSet.mounter === seriesNum) {
			// Turn off mounter if it's already showing the correct one
			state.activeSet.mounter = 0;
		} else {
			// Turn on mounter matching the profile series
			state.activeSet.mounter = seriesNum;
		}
	};

	const handleSectionClick = (sectionKey: string) => {
		if (sections[sectionKey].isToggle) {
			handleMounterToggle();
		} else if (state.current === sectionKey) {
			state.current = null;
			state.isOptionsVisible = false;
		} else {
			state.current = sectionKey;
			state.isOptionsVisible = true;
		}
	};

	const handleOptionClick = (option: string) => {
		if (!snap.current) return;

		const section = sections[snap.current];

		if (section.stateKey === "profile") {
			// Store the full profile option name
			state.activeSet.profile = option;
			// Update mounter based on the profile series (first number)
			if (state.activeSet.mounter !== 0) {
				state.activeSet.mounter = Number.parseInt(option.split("_")[1]);
			}
		} else {
			// For other sections, keep existing logic
			const number = Number.parseInt(option.split("_").pop() || "1");
			state.activeSet[section.stateKey] = number;
		}

		state.current = null;
		state.isOptionsVisible = false;
	};

	return (
		<>
			<div
				className={`
					absolute left-1/2 -translate-x-1/2
					flex flex-col
					transition-all duration-300 ease-out
					${snap.isOptionsVisible ? "bottom-20 opacity-100 pointer-events-auto" : "bottom-[60px] opacity-0 pointer-events-none"}
					bg-white/90 backdrop-blur-lg
					rounded-xl
					w-[95%] md:w-auto
					md:min-w-[400px]
					max-w-[500px]
					p-3 md:p-5
					gap-3
				`}
			>
				{snap.current && sections[snap.current].options && (
					<div>
						{snap.current === "implant" && (
							<div className="flex justify-center gap-2 md:gap-3">
								{sections.implant.options.map((option) => {
									const implantNum = Number.parseInt(
										option.split("_").pop() || "1",
									);
									const isSelected = snap.activeSet.implant === implantNum;
									return (
										<button
											key={option}
											type="button"
											onClick={() => handleOptionClick(option)}
											className={`
												flex items-center gap-1 md:gap-2
												px-2 py-2 md:px-5 md:py-3
												rounded-lg
												text-xs md:text-sm
												transition-colors
												cursor-pointer
												${
													isSelected
														? "bg-[#d2232a] text-white"
														: "bg-white/95 text-gray-600"
												}
											`}
										>
											<span className="text-base md:text-lg">⌀</span>
											<span>{sections.implant.getSize(option)}</span>
										</button>
									);
								})}
							</div>
						)}

						{snap.current === "profile" && (
							<div className="flex flex-col gap-4">
								{[1, 2, 3].map((angleNum) => (
									<div
										key={angleNum}
										className="flex items-center gap-2 md:gap-3 p-2 md:p-3 bg-black/[0.03] rounded-lg"
									>
										<div className="min-w-[40px] text-xs md:text-sm text-gray-600">
											{profileAngles[angleNum]}
										</div>
										<div className="flex gap-2 flex-1">
											{sections.profile.options
												.filter(
													(option) =>
														option.split("_")[1] === angleNum.toString(),
												)
												.map((option) => {
													const isSelected = snap.activeSet.profile === option;
													return (
														<button
															key={option}
															type="button"
															onClick={() => handleOptionClick(option)}
															className={`
																flex-1
																px-2 py-2 md:px-3 md:py-3
																rounded-lg
																text-xs md:text-sm
																transition-colors
																cursor-pointer
																${
																	isSelected
																		? "bg-[#d2232a] text-white"
																		: "bg-white/95 text-gray-600"
																}
															`}
														>
															{sections.profile.getSize(option)}
														</button>
													);
												})}
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				)}
			</div>

			{/* Section buttons */}
			<div className="absolute bottom-5 left-1/2 -translate-x-1/2 flex gap-3">
				{Object.entries(orderedSections).map(([key, section]) => {
					const isMounter = key === "mounter";
					const isMounterActive = isMounter && snap.activeSet.mounter !== 0;
					const isActive = isMounterActive || snap.current === key;

					return (
						<button
							key={key}
							onClick={() => handleSectionClick(key)}
							type="button"
							className={`
								px-2 py-2 md:px-5 md:py-2.5
								rounded-lg
								text-xs md:text-sm
								transition-colors
								cursor-pointer
								${isActive ? "bg-[#d2232a] text-white" : "bg-white/95 text-gray-600"}
							`}
						>
							{section.name}
						</button>
					);
				})}

				{/* Expand/collapse button */}
				<button
					onClick={() => {
						state.isExpanded = !state.isExpanded;
					}}
					type="button"
					className={`
						px-2 py-2 md:px-5 md:py-2.5
						rounded-lg
						text-xs md:text-sm
						transition-colors
						cursor-pointer
						${
							isIntroAnimating
								? "opacity-50 cursor-not-allowed"
								: "bg-white/95 text-gray-600"
						}
					`}
					disabled={isIntroAnimating}
					aria-label="Expand/collapse model"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						className={isIntroAnimating ? "opacity-50" : ""}
					>
						<title>Expand/Collapse Model</title>
						{snap.isExpanded ? (
							// Collapse icon (arrows pointing inward)
							<>
								<path
									fill="none"
									stroke="#000"
									strokeLinecap="round"
									strokeLinejoin="round"
									d="m9.5 20.5v-7h-7m11-11v7h7"
								/>
							</>
						) : (
							// Expand icon (arrows pointing outward)
							<>
								<path
									fill="none"
									stroke="#000"
									strokeLinecap="round"
									strokeLinejoin="round"
									d="m19.5 10.5v-7h-7m-9 9v7h7"
								/>
							</>
						)}
					</svg>
				</button>
			</div>
		</>
	);
}
